import React from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Component to inject into the React Router <BrowserRouter /> that listens for route changes
 *   - When a route change is detected, the component scrolls the window to the top
 */
const RouterScroll = () => {
  const location = useLocation();
  React.useEffect(() => {
    window.scroll({ top: 0, left: 0 });
  }, [location]);

  return null;
};

export default RouterScroll;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress, useTheme } from '@mui/joy';
import React from 'react';
import { css } from 'aphrodite';
import savingIndicatorStyles from './saving-indicator.styles';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import theme from '../../styles/theme';

export type SavingStatus = 'saving' | 'saved' | 'hidden' | 'failed';

export type SavingIndicatorProps = {
  status: SavingStatus;
};

const SavingIndicator = ({ status }: SavingIndicatorProps) => {
  const joyUiTheme = useTheme();

  return (
    <div
      className={css(savingIndicatorStyles.container)}
      style={{
        color:
          status === 'failed'
            ? joyUiTheme.colorSchemes.light.palette.danger[500]
            : theme.colour.grey,
      }}
    >
      {status !== 'hidden' && (
        <>
          <div className={css(savingIndicatorStyles.iconContainer)}>
            {status === 'saving' ? (
              <CircularProgress size='sm' />
            ) : status === 'saved' ? (
              <FontAwesomeIcon
                icon={faCircleCheck}
                size='lg'
                color={joyUiTheme.colorSchemes.light.palette.success[500]}
              />
            ) : (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                size='lg'
                color={joyUiTheme.colorSchemes.light.palette.danger[500]}
              />
            )}
          </div>
          {status === 'saving'
            ? 'Saving...'
            : status === 'saved'
              ? 'Saved!'
              : `Couldn't save`}
        </>
      )}
    </div>
  );
};

export default SavingIndicator;

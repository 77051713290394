import React from 'react';
import { Language } from '../../types/misc';
import CountryFlag from '../country-flag/country-flag';
import theme from '../../styles/theme';

export type FlagTrainProps = {
  langs: Language[];
  maxNumber?: number;
};

const FlagTrain = ({ langs, maxNumber = 8 }: FlagTrainProps) => {
  const flagsArray = React.useMemo(
    () =>
      langs
        .slice(0, maxNumber)
        .map((languageCode) => (
          <CountryFlag
            languageCode={languageCode}
            size='28px'
            key={languageCode}
          />
        )),
    [langs, maxNumber],
  );
  return (
    <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
      {flagsArray}
      {langs.length > flagsArray.length && (
        <div
          style={{
            color: theme.colour.grey,
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'center',
          }}
        >
          +{langs.length - flagsArray.length}
        </div>
      )}
    </div>
  );
};

export default FlagTrain;

import { StyleSheet } from 'aphrodite';

const projectDetailsStyles = StyleSheet.create({
  usersListContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    maxHeight: '350px',
    overflowY: 'auto',
  },
  usersListContainerTopGradient: {
    '::before': {
        content: '""',
        position: 'absolute',
        height: '40px',
        width: 'calc(100% - 48px)',
        background: 'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);'
    },
  },
  usersListContainerBottomGradient: {
    '::after': {
        content: '""',
        position: 'absolute',
        bottom: '24px',
        height: '40px',
        width: 'calc(100% - 48px)',
        background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);'
    }
  },
  languagesListContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    maxHeight: '200px',
    overflowY: 'auto',
  }
});

export default projectDetailsStyles;

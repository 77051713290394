import React from 'react';
import { Language } from '../../types/misc';

export type CountryFlagProps = {
  languageCode: Language;
  size?: string;
};

const CountryFlag = ({ languageCode, size = '16px' }: CountryFlagProps) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img
        src={`/img/flags/${languageCode}.svg`}
        width={size}
        alt={languageCode}
      />
    </div>
  );
};

export default CountryFlag;

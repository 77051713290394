import { extendTheme } from "@mui/joy";
import theme from "./theme";

const joyUiTheme = extendTheme({
    colorSchemes: {
        light: {
            palette: {
                primary: {
                    50: '#E5ECF5',
                    100: '#C9D8EA',
                    200: '#A7BDD9',
                    300: '#85A1C8',
                    400: '#6287B6',
                    500: theme.colour.primary,
                    600: '#3D5F88',
                    700: '#355273',
                    800: '#2A405C',
                    900: '#1F2D42'
                }
            }
        }
    },
    components: {
        JoyCircularProgress: {
            styleOverrides: {
                progress: ({ ownerState, theme}) => ({
                    ...(ownerState.color === 'warning' && {
                        stroke: theme.vars.palette.warning[400]
                    }),
                })
            }
        },
    }
});

export default joyUiTheme;
import React from 'react';
import { Card as JoyUICard } from '@mui/joy';
import { SxProps } from '@mui/joy/styles/types';

export type CardProps = {
  children: React.ReactElement;
  size?: 'sm' | 'md' | 'lg';
  sx?: SxProps;
  'data-testid'?: string;
};

const Card = ({ children, size = 'md', sx, "data-testid": datatestid }: CardProps) => {
  return (
    <JoyUICard sx={{ boxShadow: 'sm', ...sx }} variant='plain' size={size} data-testid={datatestid}>
      {children}
    </JoyUICard>
  );
};

export default Card;

import { StyleSheet } from 'aphrodite';

const savingIndicatorStyles = StyleSheet.create({
  container: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    width: '80px',
    height: '24px'
  },
  iconContainer: {
    minWidth: '24px'
  }
});

export default savingIndicatorStyles;
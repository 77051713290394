import React from 'react';
import theme from '../../styles/theme';

export type SubtitleProps = {
  children: string;
  style?: React.CSSProperties;
  className?: string;
};

const Subtitle = ({ children, style, className }: SubtitleProps) => {
  return (
    <div
      className={className}
      style={{
        textTransform: 'uppercase',
        fontSize: '12px',
        letterSpacing: '0.1rem',
        color: theme.colour.grey,
        marginBottom: '4px',
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default Subtitle;

import { getEnvVariables } from "../util/env-variables";
import { User } from "../types/user";
import axiosClient from "../util/axios";

const userService = {
    searchForUserByEmail: async (accessToken: string, email: string): Promise<User> => {
        const { apiRootUrl } = getEnvVariables();

        const response = await axiosClient.get(`${apiRootUrl}/user/email?email=${email}`, { headers: { Authorization: `Bearer ${accessToken}`}});

        return response.data.data as User;
    },
    getMultipleUserDetails: async (accessToken: string, userIds: string[]): Promise<User[]> => {
        const { apiRootUrl } = getEnvVariables();

        const response = await axiosClient.get(`${apiRootUrl}/user/bulk?${userIds.map((userId) => `userId=${userId}`).join('&')}`, { headers: { Authorization: `Bearer ${accessToken}`}});

        return response.data.data as User[];
    },
    getUserDetails: async (accessToken: string, userId: string): Promise<User> => {
        const { apiRootUrl } = getEnvVariables();

        const response = await axiosClient.get(`${apiRootUrl}/user/${userId}`, { headers: { Authorization: `Bearer ${accessToken}`}});

        return response.data.data as User;
    }
};

export default userService;
import { StyleSheet } from 'aphrodite';
import theme from '../../styles/theme';

const profileNameStyles = StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  nameAndProfileGroup: {
    display: 'flex',
    alignItems: 'center',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '42px',
  },
  tagline: {
    fontSize: '14px',
  },
  timeline: {
    fontSize: '14px',
    color: theme.colour.grey,
  },
  name: {
    fontWeight: 500,
  },
});

export default profileNameStyles;

import React from 'react';
import Heading from '../../components/heading/heading';
import { faAngleLeft, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Page } from '../../types/navigation';
import { Button, Link, Typography } from '@mui/joy';
import BasicReleaseDetails from '../../components/basic-release-details/basic-release-details';
import HelpBox, { HelpItem } from '../../components/help-box/help-box';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import useAuth from '../../util/auth-hook';
import projectService from '../../service/project';
import ErrorMessagePopup from '../../components/error-message/error-message-popup/error-message-popup';
import LoadingMessage from '../../components/loading-message/loading-message';
import generalUtil from '../../util/general';
import KeysTable from '../../components/keys-table/keys-table';
import SavingIndicator, {
  SavingStatus,
} from '../../components/saving-indicator/saving-indicator';
import { AppPermission, ProjectRole } from '../../types/user';
import { ReleaseStatus } from '../../types/project';
import ApproveReleaseConfirmationModal from '../../components/approve-release-confirmation-modal/approve-release-confirmation-modal';

const helpItems: HelpItem[] = [
  {
    title: 'Key',
    text: `Insert a new translation key, e.g. “global.text.label”, or add an existing key to replace it.`,
  },
  {
    title: 'Primary Value',
    text: `The initial value in your project's primary language.`,
  },
  {
    title: 'Placeholder',
    text: `Whether the text to be translated contains any placeholders or HTML tags.`,
  },
  {
    title: 'Context',
    text: `Optionally provide some context as to where the label appears in your application, or any specifics as to its meaning.`,
  },
];

const KeysScreen = () => {
  const { projectId, releaseId } = useParams();
  const { getAccessTokenSilently, userPerms } = useAuth();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [savingStatus, setSavingStatus] =
    React.useState<SavingStatus>('hidden');
  const [isApproveConfirmationOpen, setIsApproveConfirmationOpen] =
    React.useState(false);
  const [keyCount, setKeyCount] = React.useState(0);

  const setSaving = (status: SavingStatus) => {
    setSavingStatus(status);
  };

  const updateKeyCount = (count: number) => {
    setKeyCount(count);
  };

  const {
    data: basicReleaseDetails,
    isFetching: isBasicReleaseDetailsFetching,
    isError: isBasicReleaseDetailsError,
    error: basicReleaseDetailsError,
    refetch: refetchBasicReleaseDetails,
  } = useQuery(
    ['basic-release-details', releaseId],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await projectService.getBasicReleaseDetails(
        accessToken,
        Number(releaseId),
        Number(projectId),
      );
    },
    {
      staleTime: 1000 * 60,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );

  const {
    isLoading: isApproveLoading,
    isError: isApproveError,
    error: approveError,
    mutate: submitApproval,
    reset: resetSubmitApproval,
  } = useMutation(
    async () => {
      const accessToken = await getAccessTokenSilently();

      return await projectService.approveRelease(
        accessToken,
        Number(releaseId),
        Number(projectId),
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['project-details', projectId],
        });
        queryClient.invalidateQueries({
          queryKey: ['basic-release-details', releaseId],
        });
        navigate(`${Page.ProjectRoute}/${projectId}`);
      },
    },
  );

  const showApproveButton = React.useMemo(() => {
    if (!basicReleaseDetails) {
      return false;
    }

    const { status, userProjectRole } = basicReleaseDetails;

    if (status.code === ReleaseStatus.InDevelopment) {
      return (
        userProjectRole === ProjectRole.ProjectManager ||
        userProjectRole === ProjectRole.Admin ||
        userPerms?.includes(AppPermission.Admin)
      );
    }

    if (status.code === ReleaseStatus.ClientReviewPreTranslation) {
      return (
        userProjectRole === ProjectRole.TranslationCoordinator ||
        userProjectRole === ProjectRole.Admin ||
        userPerms?.includes(AppPermission.Admin)
      );
    }

    return false;
  }, [basicReleaseDetails, userPerms]);

  const keysEditable = React.useMemo(() => {
    if (!basicReleaseDetails) {
      return false;
    }

    const { status, userProjectRole } = basicReleaseDetails;

    const isAdmin = userPerms?.includes(AppPermission.Admin) ?? false;

    if (status.code === ReleaseStatus.InDevelopment) {
      return (
        userProjectRole === ProjectRole.Admin ||
        userProjectRole === ProjectRole.Developer ||
        isAdmin
      );
    }

    if (status.code === ReleaseStatus.ClientReviewPreTranslation) {
      return (
        userProjectRole === ProjectRole.Admin ||
        userProjectRole === ProjectRole.TranslationCoordinator ||
        isAdmin
      );
    }

    return false;
  }, [basicReleaseDetails, userPerms]);

  const handleApprovalCancel = () => {
    setIsApproveConfirmationOpen(false);
    resetSubmitApproval();
  }

  return (
    <>
      <ApproveReleaseConfirmationModal
        isOpen={isApproveConfirmationOpen}
        handleCancel={handleApprovalCancel}
        handleConfirm={submitApproval}
        releaseName={basicReleaseDetails?.versionName}
        status={basicReleaseDetails?.status}
        isLoading={isApproveLoading}
        isError={isApproveError}
        error={approveError}
      />
      <LoadingMessage isLoading={isBasicReleaseDetailsFetching} />
      <ErrorMessagePopup
        isError={isBasicReleaseDetailsError}
        error={basicReleaseDetailsError}
        retry={refetchBasicReleaseDetails}
      />

      <Link
        startDecorator={<FontAwesomeIcon icon={faAngleLeft} />}
        fontSize={'18px'}
        href={`${Page.ProjectRoute}/${projectId}`}
      >
        Project Details
      </Link>
      <Heading style={{ margin: '8px 0px 24px' }}>Translation Keys</Heading>
      <div style={{ display: 'flex', gap: '24px', marginBottom: '24px' }}>
        <div style={{ width: '75%', display: 'flex', gap: '24px' }}>
          <div>
            <Typography fontSize='14px'>
              {(basicReleaseDetails?.userProjectRole === ProjectRole.Admin ||
                userPerms?.includes(AppPermission.Admin)) &&
                (basicReleaseDetails?.status.code ===
                ReleaseStatus.InDevelopment ? (
                  <>
                    Whilst the release is in the 'In Development' stage, you can
                    add new translation keys for this release, as well as making
                    changes to any translation keys already added to this
                    release. You can also add existing translation keys to this
                    release, to allow them to be changed. Once you are happy,
                    you can approve the release to progress forward to the
                    'Client Review' stage
                  </>
                ) : basicReleaseDetails?.status.code ===
                  ReleaseStatus.ClientReviewPreTranslation ? (
                  <>
                    You can review the keys that have been added to this
                    release, and are able to make any changes as you see fit.
                    You can also add new keys to be translated, or add an
                    existing key from a previous release to ensure it is
                    replaced in this release. Once you are happy with the keys
                    to be translated, you can approve the release to move
                    forward to the 'Translating' stage.
                  </>
                ) : (
                  <>
                    You can review the keys that have been added to this
                    release, however you will be unable to make any changes as
                    the release is now no longer 'In Development'
                  </>
                ))}
              {basicReleaseDetails?.userProjectRole === ProjectRole.Developer &&
                (basicReleaseDetails.status.code ===
                ReleaseStatus.InDevelopment ? (
                  <>
                    Whilst the release is in the 'In Development' stage, you can
                    add new translation keys for this release, as well as making
                    changes to any translation keys already added to this
                    release. You can also add existing translation keys to this
                    release, to allow them to be changed.
                  </>
                ) : (
                  <>
                    You can review the keys that have been added to this
                    release, however you will be unable to make any changes as
                    the release is now no longer 'In Development'
                  </>
                ))}
              {basicReleaseDetails?.userProjectRole ===
                ProjectRole.ProjectManager &&
                (basicReleaseDetails.status.code ===
                ReleaseStatus.InDevelopment ? (
                  <>
                    Whilst the release is in the 'In Development' stage, you can
                    review translation keys that have been added to this release
                    so far. You are unable to make any changes. Once you are
                    happy that all keys have been added to this release, you can
                    approve the release to move forward to the 'Client Review'
                    stage.
                  </>
                ) : (
                  <>
                    You can review the keys that have been added to this
                    release, however you will be unable to make any changes as
                    the release is now no longer 'In Development'
                  </>
                ))}
              {basicReleaseDetails?.userProjectRole ===
                ProjectRole.TranslationCoordinator &&
                (basicReleaseDetails.status.code ===
                ReleaseStatus.InDevelopment ? (
                  <>
                    You can review the keys that have been added to this
                    release, however you are not able to make any changes at
                    this time as the release is still 'In Development'. Once the
                    release has been given approval to progress to the 'Client
                    Review' stage, you can review and update the keys.
                  </>
                ) : (
                  <>
                    You can review the keys that have been added to this
                    release, and are able to make any changes as you see fit.
                    You can also add new keys to be translated, or add an
                    existing key from a previous release to ensure it is
                    replaced in this release. Once you are happy with the keys
                    to be translated, you can approve the release to move
                    forward to the 'Translating' stage.
                  </>
                ))}
            </Typography>
          </div>
          <div style={{ display: 'flex', gap: '24px', alignItems: 'center' }}>
            <SavingIndicator status={savingStatus} />
            {showApproveButton && (
              <Button
                color='success'
                startDecorator={<FontAwesomeIcon icon={faCheck} />}
                onClick={() => setIsApproveConfirmationOpen(true)}
                disabled={keyCount === 0}
              >
                Approve
              </Button>
            )}
          </div>
        </div>
        <BasicReleaseDetails
          projectName={generalUtil.formatData(basicReleaseDetails?.projectName)}
          versionName={generalUtil.formatData(basicReleaseDetails?.versionName)}
          isLoading={isBasicReleaseDetailsFetching}
        />
      </div>
      <div style={{ display: 'flex', gap: '24px' }}>
        <div style={{ width: '75%' }}>
          <KeysTable
            releaseId={Number(releaseId)}
            releaseName={basicReleaseDetails?.versionName}
            setSavingStatus={setSaving}
            updateKeyCount={updateKeyCount}
            isLoading={isBasicReleaseDetailsFetching}
            editable={keysEditable}
          />
        </div>
        <div
          style={{ display: 'flex', flexGrow: 1, maxWidth: 'calc(25% - 24px)' }}
        >
          <HelpBox items={helpItems} />
        </div>
      </div>
    </>
  );
};

export default KeysScreen;

import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  Skeleton,
  Table,
  Typography,
  useTheme,
} from '@mui/joy';
import React from 'react';
import {
  TranslationKeyData,
  TranslationKeyInput,
} from '../../types/translation';
import Card from '../card/card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faPencil,
  faPlus,
  faTrashCan,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { css } from 'aphrodite';
import keysTableStyles from './keys-table.styles';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import translationService from '../../service/translation';
import ErrorMessagePopup from '../error-message/error-message-popup/error-message-popup';
import LoadingMessage from '../loading-message/loading-message';
import DeleteConfirmationModal from './delete-confirmation-modal/delete-confirmation-modal';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SavingStatus } from '../saving-indicator/saving-indicator';
import useAuth from '../../util/auth-hook';

export type KeysTableProps = {
  releaseId?: number;
  releaseName?: string;
  setSavingStatus?: (status: SavingStatus) => void;
  updateKeyCount?: (count: number) => void;
  isLoading?: boolean;
  editable: boolean;
};

type UpdateFormValues = {
  primaryValue: string;
  placeholder: boolean;
  context?: string;
};

type AddFormValues = {
  key: string;
  primaryValue: string;
  placeholder: boolean;
  context?: string;
};

const KeysTable = ({
  releaseId,
  releaseName,
  setSavingStatus,
  updateKeyCount,
  isLoading,
  editable,
}: KeysTableProps) => {
  const [rowEditing, setRowEditing] = React.useState<number | undefined>();
  const [deleteCandidate, setDeleteCandidate] = React.useState<
    string | undefined
  >();
  const [addingKey, setAddingKey] = React.useState(false);

  const joyUiTheme = useTheme();

  const updateFormValidationSchema = Yup.object().shape({
    primaryValue: Yup.string()
      .required('Primary Value is required')
      .max(2000, 'Primary Value must be no longer than 2000 characters'),
    placeholder: Yup.boolean().required(),
    context: Yup.string().max(
      2000,
      'Context must be no longer than 2000 characters',
    ),
  });

  const addFormValidationSchema = Yup.object().shape({
    key: Yup.string()
      .required('Key is required')
      .max(255, 'Key must be no longer than 255 characters'),
    primaryValue: Yup.string()
      .required('Primary Value is required')
      .max(2000, 'Primary Value must be no longer than 2000 characters'),
    placeholder: Yup.boolean().required(),
    context: Yup.string().max(
      2000,
      'Context must be no longer than 2000 characters',
    ),
  });

  const {
    control: updateFormControl,
    register: updateFormRegister,
    reset: updateFormReset,
    getValues: getValuesFromUpdateForm,
    formState: { isValid: isUpdateFormValid, errors: updateFormErrors },
  } = useForm<UpdateFormValues>({
    resolver: yupResolver(updateFormValidationSchema),
    mode: 'all',
  });

  const {
    control: addFormControl,
    register: addFormRegister,
    reset: addFormReset,
    getValues: getValuesFromAddForm,
    formState: { isValid: isAddFormValid, errors: addFormErrors },
  } = useForm<AddFormValues>({
    resolver: yupResolver(addFormValidationSchema),
    mode: 'all',
  });

  const { getAccessTokenSilently } = useAuth();

  const {
    data: existingTranslationKeys,
    isFetching: isExistingTranslationKeysFetching,
    isError: isExistingTranslationKeysError,
    error: existingTranslationKeysError,
    refetch: refetchExistingTranslationKeys,
  } = useQuery(
    ['existing-translation-keys', releaseId],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await translationService.getTranslationKeys(
        accessToken,
        Number(releaseId),
      );
    },
    {
      staleTime: 1000 * 60,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setRows(data);
      },
    },
  );

  const [rows, setRows] = React.useState<TranslationKeyData[]>(existingTranslationKeys ?? []);
  
  React.useEffect(() => {
    if (updateKeyCount) {
      updateKeyCount(rows.length);
    }
  }, [rows, updateKeyCount]);

  const {
    isLoading: isUpdateRowLoading,
    isError: isUpdateRowError,
    error: updateRowError,
    mutate: submitUpdateRow,
    reset: resetUpdateRow,
  } = useMutation(
    async (data: TranslationKeyInput) => {
      const accessToken = await getAccessTokenSilently();

      if (setSavingStatus) {
        setSavingStatus('saving');
      }

      return await translationService.updateTranslationKeys(
        accessToken,
        Number(releaseId),
        [data],
      );
    },
    {
      onSuccess: (data) => {
        setRows(data);
        setRowEditing(undefined);
        if (setSavingStatus) {
          setSavingStatus('saved');
        }
      },
      onError: () => {
        if (setSavingStatus) {
          setSavingStatus('failed');
        }
      },
    },
  );

  const {
    isLoading: isDeleteRowLoading,
    isError: isDeleteRowError,
    error: deleteRowError,
    mutate: submitDeleteRow,
    reset: resetDeleteRow,
  } = useMutation(
    async (key: string) => {
      const accessToken = await getAccessTokenSilently();

      if (setSavingStatus) {
        setSavingStatus('saving');
      }

      return await translationService.deleteTranslationKeys(
        accessToken,
        Number(releaseId),
        [key],
      );
    },
    {
      onSuccess: (data) => {
        setDeleteCandidate(undefined);
        setRows(data);
        setRowEditing(undefined);
        if (setSavingStatus) {
          setSavingStatus('saved');
        }
      },
      onError: () => {
        if (setSavingStatus) {
          setSavingStatus('failed');
        }
      },
    },
  );

  const {
    isLoading: isAddKeyLoading,
    isError: isAddKeyError,
    error: addKeyError,
    mutate: submitAddKey,
    reset: resetAddKey,
  } = useMutation(
    async () => {
      const accessToken = await getAccessTokenSilently();
      const values = getValuesFromAddForm();

      if (setSavingStatus) {
        setSavingStatus('saving');
      }

      return await translationService.addTranslationKeys(
        accessToken,
        Number(releaseId),
        [values],
      );
    },
    {
      onSuccess: (data) => {
        setRows(data);
        addFormReset();
        setAddingKey(false);
        if (setSavingStatus) {
          setSavingStatus('saved');
        }
      },
      onError: () => {
        if (setSavingStatus) {
          setSavingStatus('failed');
        }
      },
    },
  );

  const editRow = (index?: number) => {
    updateFormReset();
    setRowEditing(index);
  };

  const updateRow = (data: TranslationKeyData) => {
    const values = getValuesFromUpdateForm();
    submitUpdateRow({ ...values, key: data.key });
  };

  const deleteRow = () => {
    submitDeleteRow(deleteCandidate as string);
  };

  const showLoaders = React.useMemo(() => {
    return isLoading || isExistingTranslationKeysFetching;
  }, [isLoading, isExistingTranslationKeysFetching]);

  return (
    <>
      <DeleteConfirmationModal
        isOpen={!!deleteCandidate}
        keyValue={deleteCandidate}
        releaseName={releaseName}
        isLoading={isDeleteRowLoading}
        isError={isDeleteRowError}
        error={deleteRowError}
        handleCancel={() => {
          setDeleteCandidate(undefined);
          resetDeleteRow();
        }}
        handleConfirm={deleteRow}
      />
      <LoadingMessage isLoading={isExistingTranslationKeysFetching} />
      <ErrorMessagePopup
        isError={isExistingTranslationKeysError}
        error={existingTranslationKeysError}
        retry={refetchExistingTranslationKeys}
      />
      <ErrorMessagePopup isError={isAddKeyError} error={addKeyError} />
      <ErrorMessagePopup isError={isUpdateRowError} error={updateRowError} />
      <Card>
        <Table>
          <thead>
            <tr>
              <th
                className={css(keysTableStyles.heading)}
                style={{ width: '20%' }}
              >
                Key
              </th>
              <th className={css(keysTableStyles.heading)}>Primary Value</th>
              <th
                className={css(keysTableStyles.heading)}
                style={{ width: '10%' }}
              >
                Placeholder
              </th>
              <th className={css(keysTableStyles.heading)}>Context</th>
              <th
                className={css(keysTableStyles.heading)}
                style={{ width: '8%' }}
              ></th>
            </tr>
          </thead>
          <tbody>
            {showLoaders ? (
              <tr>
                <td colSpan={5}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '16px',
                    }}
                  >
                    <Skeleton variant='rectangular' height={'50px'} />
                    <Skeleton variant='rectangular' height={'50px'} />
                    <Skeleton variant='rectangular' height={'50px'} />
                    <Skeleton variant='rectangular' height={'50px'} />
                  </div>
                </td>
              </tr>
            ) : (
              rows?.map((data, index) => (
                <tr
                  key={`${data.translationId}-${data.key}`}
                  data-testid={`key-table-row-${data.translationId}`}
                >
                  <td>
                    <div style={{ display: 'flex' }}>
                      <Typography fontFamily={'monospace'} variant='soft'>
                        {data.key}
                      </Typography>
                    </div>
                  </td>
                  <td>
                    {rowEditing === index ? (
                      <FormControl>
                        <Input
                          {...updateFormRegister('primaryValue')}
                          defaultValue={data.primaryValue}
                          error={!!updateFormErrors.primaryValue}
                        />
                        {!!updateFormErrors.primaryValue && (
                          <FormHelperText
                            sx={{ color: joyUiTheme.vars.palette.danger[500] }}
                          >
                            {updateFormErrors.primaryValue.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    ) : (
                      <div>{data.primaryValue}</div>
                    )}
                  </td>
                  <td>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {rowEditing === index ? (
                        <Controller
                          name='placeholder'
                          control={updateFormControl}
                          defaultValue={data.placeholder}
                          render={({ field: props }) => (
                            <Checkbox
                              checked={props.value}
                              onChange={(e) => props.onChange(e.target.checked)}
                            />
                          )}
                        />
                      ) : (
                        <Checkbox checked={data.placeholder} disabled />
                      )}
                    </div>
                  </td>
                  <td>
                    {rowEditing === index ? (
                      <FormControl>
                        <Input
                          {...updateFormRegister('context')}
                          defaultValue={data.context}
                          error={!!updateFormErrors.context}
                        />
                        {!!updateFormErrors.context && (
                          <FormHelperText
                            sx={{ color: joyUiTheme.vars.palette.danger[500] }}
                          >
                            {updateFormErrors.context.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    ) : (
                      <div>{data.context}</div>
                    )}
                  </td>
                  <td>
                    {editable && (
                      <div
                        style={{
                          display: 'flex',
                          gap: '8px',
                          justifyContent: 'flex-end',
                        }}
                      >
                        {rowEditing !== index ? (
                          <>
                            <IconButton
                              color='primary'
                              variant='solid'
                              aria-label='Edit'
                              onClick={() => editRow(index)}
                              disabled={rowEditing !== undefined || addingKey}
                            >
                              <FontAwesomeIcon icon={faPencil} />
                            </IconButton>
                            <IconButton
                              color='danger'
                              variant='solid'
                              aria-label='Delete'
                              disabled={rowEditing !== undefined || addingKey}
                              onClick={() => setDeleteCandidate(data.key)}
                            >
                              <FontAwesomeIcon icon={faTrashCan} />
                            </IconButton>
                          </>
                        ) : (
                          <>
                            <IconButton
                              color='success'
                              variant='solid'
                              aria-label='Confirm'
                              onClick={() => updateRow(data)}
                              loading={isUpdateRowLoading}
                              disabled={!isUpdateFormValid}
                            >
                              <FontAwesomeIcon icon={faCheck} />
                            </IconButton>
                            <IconButton
                              color='danger'
                              variant='solid'
                              aria-label='Cancel'
                              onClick={() => {
                                editRow(undefined);
                                resetUpdateRow();
                              }}
                            >
                              <FontAwesomeIcon icon={faXmark} />
                            </IconButton>
                          </>
                        )}
                      </div>
                    )}
                  </td>
                </tr>
              ))
            )}
            {(editable || rows.length === 0) && (
              <tr data-testid='key-table-row-new'>
                {!showLoaders && (
                  <>
                    {addingKey ? (
                      <>
                        <td>
                          <FormControl>
                            <Input
                              {...addFormRegister('key')}
                              error={!!addFormErrors.key}
                              placeholder='Key...'
                            />
                            {!!addFormErrors.key && (
                              <FormHelperText
                                sx={{
                                  color: joyUiTheme.vars.palette.danger[500],
                                }}
                              >
                                {addFormErrors.key.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </td>
                        <td>
                          <FormControl>
                            <Input
                              {...addFormRegister('primaryValue')}
                              error={!!addFormErrors.primaryValue}
                              placeholder='Primary Value...'
                            />
                            {!!addFormErrors.primaryValue && (
                              <FormHelperText
                                sx={{
                                  color: joyUiTheme.vars.palette.danger[500],
                                }}
                              >
                                {addFormErrors.primaryValue.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </td>
                        <td>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <Controller
                              name='placeholder'
                              control={addFormControl}
                              defaultValue={false}
                              render={({ field: props }) => (
                                <Checkbox
                                  checked={props.value}
                                  onChange={(e) =>
                                    props.onChange(e.target.checked)
                                  }
                                />
                              )}
                            />
                          </div>
                        </td>
                        <td>
                          <FormControl>
                            <Input
                              {...addFormRegister('context')}
                              error={!!addFormErrors.context}
                              placeholder='Context...'
                            />
                            {!!addFormErrors.context && (
                              <FormHelperText
                                sx={{
                                  color: joyUiTheme.vars.palette.danger[500],
                                }}
                              >
                                {addFormErrors.context.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </td>
                        <td>
                          <div
                            style={{
                              display: 'flex',
                              gap: '8px',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <IconButton
                              color='success'
                              variant='solid'
                              loading={isAddKeyLoading}
                              disabled={!isAddFormValid}
                              aria-label='Confirm'
                              onClick={() => submitAddKey()}
                            >
                              <FontAwesomeIcon icon={faCheck} />
                            </IconButton>
                            <IconButton
                              color='danger'
                              variant='solid'
                              aria-label='Cancel'
                              onClick={() => {
                                addFormReset();
                                setAddingKey(false);
                                resetAddKey();
                              }}
                            >
                              <FontAwesomeIcon icon={faXmark} />
                            </IconButton>
                          </div>
                        </td>
                      </>
                    ) : (
                      <td colSpan={5}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                          }}
                        >
                          {rows?.length === 0 && (
                            <div style={{ margin: '8px 0' }}>
                              There are no keys added to this release yet
                            </div>
                          )}
                          {editable && (
                            <div>
                              <Button
                                startDecorator={
                                  <FontAwesomeIcon icon={faPlus} />
                                }
                                onClick={() => setAddingKey(true)}
                                disabled={rowEditing !== undefined}
                              >
                                Add Key
                              </Button>
                            </div>
                          )}
                        </div>
                      </td>
                    )}
                  </>
                )}
              </tr>
            )}
          </tbody>
        </Table>
      </Card>
    </>
  );
};

export default KeysTable;

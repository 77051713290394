import React from 'react';
import {
  ProjectReleaseStatusData,
  ReleaseStatus as ReleaseStatusType,
} from '../../types/project';
import { CircularProgress, Tooltip, useTheme } from '@mui/joy';
import { DefaultColorPalette } from '@mui/joy/styles/types';

export type ReleaseStatusProps = {
  status?: ProjectReleaseStatusData;
  size?: 'sm' | 'md' | 'lg';
  showLabel?: boolean;
};

type ProgressData = {
  percentage: number;
  colour?: DefaultColorPalette;
};

/**
 * Maps a project Release Status to a percentage value and a colour
 */
const getProgressDataFromStatus = (
  status?: ReleaseStatusType,
): ProgressData => {
  const unitSize = 100 / 5;

  switch (status) {
    case 'INDEV': {
      return {
        percentage: unitSize,
        colour: 'danger',
      };
    }
    case 'CRPRE': {
      return {
        percentage: unitSize * 2,
        colour: 'warning',
      };
    }
    case 'TRANS': {
      return {
        percentage: unitSize * 3,
        colour: 'success',
      };
    }
    case 'CRPOS': {
      return {
        percentage: unitSize * 4,
        colour: 'primary',
      };
    }
    case 'RELSD': {
      return {
        percentage: 100,
        colour: 'neutral',
      };
    }
    default: {
      return {
        percentage: 0,
      };
    }
  }
};

const ReleaseStatus = ({
  status,
  size = 'md',
  showLabel = true,
}: ReleaseStatusProps) => {
  const { percentage, colour } = React.useMemo(
    () => getProgressDataFromStatus(status?.code),
    [status],
  );

  const joyUiTheme = useTheme();

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      {showLabel ? (
        <CircularProgress
          determinate
          value={percentage}
          color={colour}
          size={size}
        />
      ) : (
        <Tooltip title={status?.description} arrow placement='top'>
          <CircularProgress
            determinate
            value={percentage}
            color={colour}
            size={size}
          />
        </Tooltip>
      )}
      {showLabel && (
        <div
          style={{
            fontWeight: size === 'sm' ? 600 : 'bold',
            fontSize: '18px',
            color: colour
              ? joyUiTheme.colorSchemes.light.palette[colour][
                  colour === 'warning' ? 400 : 500
                ]
              : 'black',
            textWrap: 'nowrap',
          }}
        >
          {status?.description ?? 'Unreleased'}
        </div>
      )}
    </div>
  );
};

export default ReleaseStatus;

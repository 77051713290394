const generalUtil = {
    /**
     * 
     * @param isoDateString  - an ISO date string
     * @returns - a date in the format e.g. Mon 11 Nov 2024
     */
    formatDate: (isoDateString: string): string => {
        const formatOptions: Intl.DateTimeFormatOptions = {
            weekday: 'short',
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        };

        return new Intl.DateTimeFormat('en-GB', formatOptions).format(new Date(isoDateString)).replace(/,/g, '');
    },
    /**
     * 
     * @param data - the data to format, which is potentially undefined
     * @param transform - a function to transform the data if it is defined
     * @returns - the data (optionally transformed), or a placeholder
     */
    formatData: (data?: string, transform?: (data: string) => string): string => {
        return data ? transform ? transform(data) : data : '--';
    },
        /**
     * 
     * @param dateString date string that we want to check validity of
     * @returns `true` if `dateString` is a valid ISO date string, or `false` if it is not
     */
        isValidISODate: (dateString: string) => {
            try {
                const date = new Date(dateString);
                return date.toISOString() === dateString;
            } catch (err) {
                // if errors, likely an invalid date string
                return false;
            } 
        },
        /**
         * @returns `true` if date1 is greater than (after) date2, `false` if date1 is less than (before) date2, or `undefined` if they are equal
         */
        compareDates: (date1: string, date2: string) => {
            if (date1 === date2) {
                return undefined;
            }
    
            return new Date(date1) > new Date(date2);
        }
};

export default generalUtil;
import React from 'react';
import { useParams } from 'react-router-dom';
import Heading from '../../components/heading/heading';
import { Button, Link, Skeleton, Typography } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleLeft,
  faFileCircleQuestion,
  faFileLines,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';
import projectService from '../../service/project';
import LoadingMessage from '../../components/loading-message/loading-message';
import ErrorMessagePopup from '../../components/error-message/error-message-popup/error-message-popup';
import { Page } from '../../types/navigation';
import ProjectVersion from '../../components/project-version/project-version';
import Card from '../../components/card/card';
import { ReleaseStatus as ReleaseStatusType } from '../../types/project';
import Subtitle from '../../components/subtitle/subtitle';
import userService from '../../service/user';
import { ProjectRole, ProjectRoleData, User } from '../../types/user';
import miscService from '../../service/misc';
import ProjectTeam from '../../components/project-team/project-team';
import LanguageList from '../../components/language-list/language-list';
import theme from '../../styles/theme';
import CreateReleaseModal from './create-release-modal/create-release-modal';

export type ProjectUserData = {
  user: User;
  role?: ProjectRoleData;
};

/**
 * Sorts the user data obtained in the useMemo below to:
 *  - Place the current user at the beginning
 *  - Sort the rest of the users in alphabetical order
 */
const sortUserData = (
  userData: ProjectUserData[],
  selfId: string,
): ProjectUserData[] => {
  const selfIndex = userData.findIndex((data) => data.user.id === selfId);

  const [selfData] = userData.splice(selfIndex, 1);
  return [
    selfData,
    ...userData.sort((a, b) =>
      a.user.name.full.toLowerCase() > b.user.name.full.toLowerCase() ? 1 : -1,
    ),
  ];
};

const ProjectDetails = () => {
  const { projectId } = useParams();

  const { getAccessTokenSilently, user } = useAuth0();

  const [isCreateReleaseModalOpen, setIsCreateReleaseModalOpen] =
    React.useState(false);

  const {
    data: projectDetails,
    isFetching: isProjectDetailsFetching,
    isSuccess: isProjectDetailsSuccess,
    isError: isProjectDetailsError,
    error: projectDetailsError,
    refetch: refetchProjectDetails,
  } = useQuery(
    ['project-details', projectId],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await projectService.getProjectDetails(
        accessToken,
        Number(projectId),
      );
    },
    {
      staleTime: 1000 * 60,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );

  const {
    data: projectRoles,
    isFetching: isProjectRolesFetching,
    isSuccess: isProjectRolesSuccess,
    isError: isProjectRolesError,
    error: projectRolesError,
    refetch: refetchProjectRoles,
  } = useQuery(
    'roles',
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await miscService.getRoles(accessToken);
    },
    {
      staleTime: 1000 * 60 * 5,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );

  const {
    data: allLanguages,
    isFetching: isAllLanguagesFetching,
    isError: isAllLanguagesError,
    error: allLanguagesError,
    refetch: refetchAllLanguages,
  } = useQuery(
    'languages',
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await miscService.getLanguages(accessToken);
    },
    {
      staleTime: 1000 * 60 * 5,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );

  const {
    data: projectUserDetails,
    isFetching: isProjectUsersFetching,
    isSuccess: isProjectUsersSuccess,
    isError: isProjectUsersError,
    error: projectUsersError,
    refetch: refetchProjectUsers,
  } = useQuery(
    'project-users',
    async () => {
      const accessToken = await getAccessTokenSilently();
      return projectDetails?.users.length === 1
        ? [
            await userService.getUserDetails(
              accessToken,
              projectDetails.users[0].userId,
            ),
          ]
        : await userService.getMultipleUserDetails(
            accessToken,
            projectDetails?.users.map((user) => user.userId) as string[],
          );
    },
    {
      enabled: isProjectDetailsSuccess,
      staleTime: 1000 * 60,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );

  const currentVersion = React.useMemo(
    () =>
      projectDetails?.versions.filter(
        (version) => version.status.code !== ReleaseStatusType.Released,
      ),
    [projectDetails],
  );

  const releasedVersions = React.useMemo(
    () =>
      projectDetails?.versions.filter(
        (version) => version.status.code === ReleaseStatusType.Released,
      ),
    [projectDetails],
  );

  const currentUserRole = React.useMemo(
    () => projectDetails?.users.find((usr) => usr.userId === user?.sub)?.role,
    [projectDetails, user],
  );

  const projectUserData = React.useMemo(() => {
    const getProjectUserData = (user: User): ProjectUserData => {
      const userRole = projectDetails?.users.find(
        (projectUser) => projectUser.userId === user.id,
      )?.role;
      return {
        user,
        role: projectRoles?.find((data) => data.id === userRole),
      };
    };

    if (isProjectUsersSuccess && isProjectRolesSuccess) {
      return sortUserData(
        projectUserDetails.map((user) => getProjectUserData(user)),
        user?.sub as string,
      );
    }
  }, [
    isProjectUsersSuccess,
    isProjectRolesSuccess,
    projectUserDetails,
    projectDetails,
    projectRoles,
    user,
  ]);

  const handleCreateReleaseModalClose = (created: boolean) => {
    setIsCreateReleaseModalOpen(false);
    if (created) {
      refetchProjectDetails();
    }
  };

  return (
    <>
      {projectDetails && (
        <CreateReleaseModal
          isOpen={isCreateReleaseModalOpen}
          onClose={handleCreateReleaseModalClose}
          projectId={projectDetails.id}
        />
      )}

      <LoadingMessage
        isLoading={
          isProjectDetailsFetching ||
          isProjectUsersFetching ||
          isProjectRolesFetching ||
          isAllLanguagesFetching
        }
      />
      <ErrorMessagePopup
        isError={isProjectDetailsError}
        error={projectDetailsError}
        retry={refetchProjectDetails}
      />
      <ErrorMessagePopup
        isError={isProjectUsersError}
        error={projectUsersError}
        retry={refetchProjectUsers}
      />
      <ErrorMessagePopup
        isError={isProjectRolesError}
        error={projectRolesError}
        retry={refetchProjectRoles}
      />
      <ErrorMessagePopup
        isError={isAllLanguagesError}
        error={allLanguagesError}
        retry={refetchAllLanguages}
      />
      <Link
        startDecorator={<FontAwesomeIcon icon={faAngleLeft} />}
        fontSize={'18px'}
        href={Page.Dashboard}
      >
        Projects
      </Link>
      <Skeleton loading={isProjectDetailsFetching} variant='inline'>
        <Heading style={{ margin: '8px 0px 24px' }}>
          {projectDetails?.name ?? '--'}
        </Heading>
      </Skeleton>
      <div
        style={{
          display: 'flex',
          gap: '48px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '32px',
            width: 'calc(75% - 48px)',
          }}
        >
          <div>
            <Subtitle style={{ fontSize: '16px', marginBottom: '16px' }}>
              Current Version
            </Subtitle>
            {isProjectDetailsFetching ? (
              <div>
                <Skeleton
                  width={'100%'}
                  height={'81px'}
                  loading={isProjectDetailsFetching}
                  sx={{ position: 'relative' }}
                />
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '24px',
                }}
              >
                {currentVersion?.map((version) => (
                  <ProjectVersion
                    key={version.id}
                    version={version}
                    projectId={projectDetails?.id as number}
                  />
                ))}
              </div>
            )}
            {currentVersion?.length === 0 && (
              <div
                style={{
                  color: theme.colour.grey,
                  textAlign: 'center',
                  margin: '24px 0',
                }}
              >
                <FontAwesomeIcon
                  icon={faFileCircleQuestion}
                  color='inherit'
                  size='3x'
                />
                <Typography sx={{ color: 'inherit', margin: '16px 0' }}>
                  There are currently no upcoming versions of{' '}
                  <Typography
                    variant='outlined'
                    sx={{
                      padding: '6px',
                      borderRadius: '8px',
                      color: 'inherit',
                    }}
                    fontWeight={600}
                  >
                    {projectDetails?.name}
                  </Typography>
                </Typography>
                {(currentUserRole === ProjectRole.ProjectManager ||
                  currentUserRole === ProjectRole.Admin) && (
                  <Typography sx={{ color: 'inherit' }}>
                    You can create a new release by clicking{' '}
                    <Typography
                      variant='outlined'
                      sx={{
                        padding: '6px',
                        borderRadius: '8px',
                        color: 'inherit',
                      }}
                      fontWeight={600}
                    >
                      + New Release
                    </Typography>{' '}
                    above
                  </Typography>
                )}
              </div>
            )}
          </div>
          <div>
            <Subtitle style={{ fontSize: '16px', marginBottom: '16px' }}>
              Released Versions
            </Subtitle>
            {isProjectDetailsFetching ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '24px',
                }}
              >
                {[...Array(3)].map((_, index) => (
                  <Skeleton
                    key={`loader-${index}`}
                    width={'100%'}
                    height={'81px'}
                    loading={isProjectDetailsFetching}
                    sx={{ position: 'relative' }}
                  />
                ))}
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '24px',
                }}
              >
                {releasedVersions?.map((version) => (
                  <ProjectVersion
                    key={version.id}
                    version={version}
                    projectId={projectDetails?.id as number}
                  />
                ))}
                {releasedVersions?.length === 0 && (
                  <div
                    style={{
                      color: theme.colour.grey,
                      textAlign: 'center',
                      margin: '24px 0',
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faFileCircleQuestion}
                      color='inherit'
                      size='3x'
                    />
                    <Typography sx={{ color: 'inherit', margin: '16px 0' }}>
                      There are currently no versions of{' '}
                      <Typography
                        variant='outlined'
                        sx={{
                          padding: '6px',
                          borderRadius: '8px',
                          color: 'inherit',
                        }}
                        fontWeight={600}
                      >
                        {projectDetails?.name}
                      </Typography>{' '}
                      that have been released
                    </Typography>
                    <Typography sx={{ color: 'inherit' }}>
                      Once a project has been released, it will be listed here
                    </Typography>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            width: '25%',
            minWidth: '250px',
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '16px',
            }}
          >
            {(currentUserRole === ProjectRole.ProjectManager ||
              currentUserRole === ProjectRole.Admin) &&
              currentVersion?.length === 0 && (
                <Button
                  startDecorator={<FontAwesomeIcon icon={faPlus} />}
                  onClick={() => setIsCreateReleaseModalOpen(true)}
                >
                  Create release
                </Button>
              )}
            <Button startDecorator={<FontAwesomeIcon icon={faFileLines} />}>
              View all translations
            </Button>
          </div>
          <ProjectTeam projectUserData={projectUserData} />
          <Card sx={{ padding: '24px' }}>
            <h2 style={{ margin: 0 }}>Activity</h2>
          </Card>
          <LanguageList langs={projectDetails?.langs} allLangs={allLanguages} />
        </div>
      </div>
    </>
  );
};

export default ProjectDetails;

import React from 'react';
import { Helmet } from 'react-helmet';

export type PageProps = {
    component: React.ReactElement;
    pageTitle: string;
}

const Page = ({ component, pageTitle }: PageProps) => {
    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            {component}
        </>
    )
}

export default Page;
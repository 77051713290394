export interface User {
    id: string
    email: string
    name: {
        full: string
        forename: string
        surname?: string
    },
    picture?: string;
}

export type ProjectRoleData = {
    id: ProjectRole;
    description: string;
}

export enum AppPermission {
    User = 'OT_USER',
    Admin = 'OT_ADMIN',
}

export enum ProjectRole {
    Developer = 'DEVLP',
    ProjectManager = 'PRJMN',
    TranslationCoordinator = 'TRNCO',
    Translator = 'TRNSL',
    Admin = 'ADMIN'
}

export const projectRoles = Object.values(ProjectRole);
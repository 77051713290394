import React from 'react';
import { Outlet } from "react-router-dom"
import NavigationBar from "./navigation-bar/navigation-bar"
import { withAuthenticationRequired } from "@auth0/auth0-react"
import { css } from "aphrodite"
import layoutStyles from "./layout.styles"

const Layout = () => {
    return (
        <>
            <NavigationBar />
            <div className={css(layoutStyles.contentContainer)}>
                <Outlet />
            </div>
        </>
    )
}

export default withAuthenticationRequired(Layout);
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useTheme } from '@mui/joy';
import React from 'react';
import axios, { AxiosError } from 'axios';

export type ErrorMessageProps = {
  isError: boolean;
  error?: unknown;
  retry?: () => void;
};

const ErrorMessage = ({ isError, error, retry }: ErrorMessageProps) => {
  const joyUiTheme = useTheme();
  return (
    <>
      {isError && (
        <div
          style={{
            color: joyUiTheme.colorSchemes.light.palette.danger[600],
            backgroundColor: joyUiTheme.colorSchemes.light.palette.danger[100],
            border: `1px ${joyUiTheme.colorSchemes.light.palette.danger[600]} solid`,
            borderRadius: '8px',
            padding: '16px',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px'
          }}
        >
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <FontAwesomeIcon
              icon={faCircleExclamation}
              color={joyUiTheme.colorSchemes.light.palette.danger[600]}
            />
            <b>Something went wrong</b>
          </div>
          <div>
            <div>
              {error instanceof AxiosError
                ? error.response?.data?.error?.message ?? error?.message
                : 'An unknown error occurred'}
            </div>
            {retry ? (
              <Link onClick={retry} color='danger' sx={{ fontSize: '14px' }}>
                Please click here to try again
              </Link>
            ) : (
              <>Please try again</>
            )}
          </div>
          {axios.isAxiosError(error) && (
            <div style={{ fontSize: '12px' }}>
              <div>Correlation ID: {error.config?.headers['correlation-id']}</div>
              <div>Error Code: {error.response?.data?.error?.code}</div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ErrorMessage;

import React from 'react';

export type HeadingProps = {
  children: string | React.ReactElement;
  style?: React.CSSProperties;
};

const Heading = ({ children, style }: HeadingProps) => {
  return <h1 style={{ fontSize: '48px', width: 'fit-content', ...style }}>{children}</h1>;
};


export default Heading;
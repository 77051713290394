const theme = {
    colour: {
        black: '#000000',
        white: '#FFFFFF',
        primary: '#476C9B',
        background: '#F0F0F0',
        grey: '#757575',
    }
}

export default theme;
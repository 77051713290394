import React from 'react';
import Card from '../card/card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import theme from '../../styles/theme';

export type HelpItem = {
  title: string;
  text: string;
};

export type HelpBoxProps = {
  items: HelpItem[];
};

const HelpBox = ({ items }: HelpBoxProps) => {
  return (
    <Card sx={{ width: '100%', padding: '24px', height: 'fit-content' }} data-testid='help-box'>
      <>
        <div style={{ margin: '8px 0' }}>
          <FontAwesomeIcon
            icon={faCircleInfo}
            color={theme.colour.primary}
            size='4x'
          />
        </div>
        <div style={{ fontSize: '14px', display: 'flex', flexDirection: 'column', gap: '16px' }}>
          {items.map((item, index) => (
            <div key={`${item.title}-${index}`}>
              <div style={{ fontWeight: 'bold' }}>{item.title}</div>
              <div>{item.text}</div>
            </div>
          ))}
        </div>
      </>
    </Card>
  );
};

export default HelpBox;

import { StyleSheet } from 'aphrodite';
import theme from '../../styles/theme';

const projectCardStyles = StyleSheet.create({
  heading: {
    backgroundColor: theme.colour.primary,
    borderRadius: '8px 8px 0 0',
    padding: '10px 16px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  headingText: {
    color: theme.colour.white,
    fontSize: '18px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  contentContainer: {
    padding: '8px 32px 32px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1
  },
});

export default projectCardStyles;

import React from 'react';
import Card from '../card/card';
import { css } from 'aphrodite';
import projectDetailsStyles from '../../pages/project-details/project-details.styles';
import { Language, LanguageData } from '../../types/misc';
import CountryFlag from '../country-flag/country-flag';
import { Skeleton } from '@mui/joy';

export type LanguageListProps = {
  langs?: Language[];
  allLangs?: LanguageData[];
};

const LanguageList = ({ langs, allLangs }: LanguageListProps) => {
  const listRef = React.useRef<HTMLDivElement>(null);

  const [showTopGradient, setShowTopGradient] = React.useState(false);
  const [showBottomGradient, setShowBottomGradient] = React.useState(false);

  React.useEffect(() => {
    const langList = listRef.current;

    const handleScroll = () => {
      if (langs && langs.length > 6 && langList && langList.scrollTop > 0) {
        setShowTopGradient(true);
      } else {
        setShowTopGradient(false);
      }

      if (
        langs &&
        langs.length > 6 &&
        langList &&
        langList.scrollTop + langList.clientHeight < langList.scrollHeight
      ) {
        setShowBottomGradient(true);
      } else {
        setShowBottomGradient(false);
      }
    };

    // run initially
    handleScroll();

    langList?.addEventListener('scroll', handleScroll);

    return () => {
      langList?.removeEventListener('scroll', handleScroll);
    };
  }, [langs]);

  return (
    <Card sx={{ padding: '24px' }}>
      <>
        <h2 style={{ margin: 0 }}>Languages</h2>
        {langs && allLangs ? (
          <div
            className={css(
              projectDetailsStyles.languagesListContainer,
              showTopGradient &&
                projectDetailsStyles.usersListContainerTopGradient,
              showBottomGradient &&
                projectDetailsStyles.usersListContainerBottomGradient,
            )}
            ref={listRef}
          >
            {langs?.map((lang) => (
              <div style={{ display: 'flex', gap: '8px' }} key={lang}>
                <CountryFlag languageCode={lang} size='24px' />
                <div>
                  {allLangs?.find((langData) => langData.code === lang)?.name}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
          >
            <Skeleton
              width={'100%'}
              height={'24px'}
              loading
              sx={{ position: 'relative' }}
            />
            <Skeleton
              width={'100%'}
              height={'24px'}
              loading
              sx={{ position: 'relative' }}
            />
            <Skeleton
              width={'100%'}
              height={'24px'}
              loading
              sx={{ position: 'relative' }}
            />
            <Skeleton
              width={'100%'}
              height={'24px'}
              loading
              sx={{ position: 'relative' }}
            />
          </div>
        )}
      </>
    </Card>
  );
};

export default LanguageList;

import React from 'react';

interface ScreenSizeContextType {
  screenWidth: number;
}

const ScreenSizeContext = React.createContext<
  ScreenSizeContextType | undefined
>(undefined);

export const useScreenSizeContext = () => {
  const context = React.useContext(ScreenSizeContext);
  if (!context) {
    throw new Error('useScreenSize must be used within a ScreenSizeProvider');
  }
  return context;
};

export const ScreenSizeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <ScreenSizeContext.Provider
      value={{
        screenWidth,
      }}
    >
      {children}
    </ScreenSizeContext.Provider>
  );
};

import { faTrashCan, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  Typography,
  useTheme,
} from '@mui/joy';
import React from 'react';
import ErrorMessage from '../../error-message/error-message';

export type DeleteConfirmationModalProps = {
  isOpen: boolean;
  handleCancel: () => void;
  handleConfirm: () => void;
  keyValue?: string;
  releaseName?: string;
  isLoading: boolean;
  isError: boolean;
  error?: unknown;
};

const DeleteConfirmationModal = ({
  isOpen,
  handleCancel,
  handleConfirm,
  keyValue,
  releaseName,
  isLoading,
  isError,
  error,
}: DeleteConfirmationModalProps) => {
  const joyUiTheme = useTheme();
  return (
    <Modal open={isOpen}>
      <ModalDialog minWidth={'500px'}>
        <DialogTitle level='h3'>Delete Key?</DialogTitle>
        <DialogContent>
          <div
            style={{
              margin: '16px 0',
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              Are you sure you want to delete
              <div style={{ display: 'flex', margin: '0 8px' }}>
                <Typography fontFamily='monospace' variant='soft'>
                  {keyValue}
                </Typography>
              </div>
              from {releaseName}?
            </div>
            <div>This will not affect this key in any previous releases</div>
            <div
              style={{
                color: joyUiTheme.colorSchemes.light.palette.danger[500],
              }}
            >
              This action <b>cannot</b> be undone
            </div>
            <ErrorMessage isError={isError} error={error} retry={handleConfirm} />
          </div>
          <div
            style={{ display: 'flex', justifyContent: 'flex-end', gap: '16px' }}
          >
            <Button
              startDecorator={<FontAwesomeIcon icon={faXmark} />}
              onClick={handleCancel}
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              color='danger'
              startDecorator={<FontAwesomeIcon icon={faTrashCan} />}
              onClick={handleConfirm}
              loading={isLoading}
            >
              Confirm
            </Button>
          </div>
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
};

export default DeleteConfirmationModal;

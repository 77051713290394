import { StyleSheet } from "aphrodite";

const basicReleaseDetailsStyles = StyleSheet.create({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        flexGrow: 1,
        gap: '8px',
        padding: '0 16px',
        textAlign: 'right'
    },
    dataText: {
        fontSize: '22px',
        fontWeight: 600,
    },
});

export default basicReleaseDetailsStyles;
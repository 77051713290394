import { StyleSheet } from "aphrodite";
import theme from "../../../styles/theme";

const navigationBarStyles = StyleSheet.create({
    barContainer: {
        backgroundColor: theme.colour.primary,
        height: '50px',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '24px 48px',
        boxShadow: `10px 10px 26px -10px rgba(0,0,0,0.75)`,
    },
    profileContainer: {
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        gap: '16px',
        flexWrap: 'wrap',
        color: theme.colour.white,
        fontWeight: 600,
    },
    imageContainer: {
        height: '100%',
    },
    roundedImage: {
        objectFit: 'cover',
        borderRadius: '50%'
    },
    divider: {
        backgroundColor: theme.colour.white,
        width: '2px',
        height: '100%'
    },
    signOutContainer: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
    userName: {
        fontSize: '18px'
    }
});

export default navigationBarStyles;
import { faCheck, faTrashCan, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  useTheme,
} from '@mui/joy';
import React from 'react';
import ErrorMessage from '../error-message/error-message';
import { ProjectReleaseStatusData } from '../../types/project';
import { getNextReleaseStatusData } from './approve-release-confirmation-modal.helper';

export type ApproveReleaseConfirmationModalProps = {
  isOpen: boolean;
  handleCancel: () => void;
  handleConfirm: () => void;
  releaseName?: string;
  status?: ProjectReleaseStatusData;
  isLoading: boolean;
  isError: boolean;
  error?: unknown;
};

const ApproveReleaseConfirmationModal = ({
  isOpen,
  handleCancel,
  handleConfirm,
  status,
  releaseName,
  isLoading,
  isError,
  error,
}: ApproveReleaseConfirmationModalProps) => {
  const joyUiTheme = useTheme();

  const nextReleaseStatus = React.useMemo(
    () => getNextReleaseStatusData(status?.code),
    [status],
  );

  return (
    <Modal open={isOpen}>
      <ModalDialog minWidth={'500px'} maxWidth={'500px'}>
        <DialogTitle level='h3'>Approve Release</DialogTitle>
        <DialogContent>
          <div
            style={{
              margin: '16px 0',
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <div>
              Are you sure you want to approve release <b>{releaseName}</b>{' '}
              to move to <b>{nextReleaseStatus?.description}</b>
            </div>
            <div
              style={{
                color: joyUiTheme.colorSchemes.light.palette.danger[500],
              }}
            >
              This action <b>cannot</b> be undone
            </div>
            <ErrorMessage
              isError={isError}
              error={error}
              retry={handleConfirm}
            />
          </div>
          <div
            style={{ display: 'flex', justifyContent: 'flex-end', gap: '16px' }}
          >
            <Button
              startDecorator={<FontAwesomeIcon icon={faXmark} />}
              onClick={handleCancel}
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              color='success'
              startDecorator={<FontAwesomeIcon icon={faCheck} />}
              onClick={handleConfirm}
              loading={isLoading}
            >
              Confirm
            </Button>
          </div>
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
};

export default ApproveReleaseConfirmationModal;

import { Snackbar, CircularProgress } from '@mui/joy';

export type LoadingMessageProps = {
  isLoading: boolean;
};

const LoadingMessage = ({ isLoading }: LoadingMessageProps) => {
  return (
    <Snackbar open={isLoading}>
      <>
        <CircularProgress />
        Loading...
      </>
    </Snackbar>
  );
};

export default LoadingMessage;

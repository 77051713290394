import { AxiosError } from "axios";
import { TranslationKeyData, TranslationKeyInput } from "../types/translation";
import axiosClient from "../util/axios";
import { getEnvVariables } from "../util/env-variables";

const translationService = {
    getTranslationKeys: async (accessToken: string, releaseId: number): Promise<TranslationKeyData[]> => {
        try {
            const { apiRootUrl } = getEnvVariables();

            const response = await axiosClient.get(`${apiRootUrl}/translation/keys/${releaseId}`, { headers: { Authorization: `Bearer ${accessToken}` } });

            return response.data.data as TranslationKeyData[];
        } catch (err) {
            return Promise.reject(err as Error);
        }
    },
    updateTranslationKeys: async (accessToken: string, releaseId: number, data: TranslationKeyInput[]): Promise<TranslationKeyData[]> => {
        try {
            const { apiRootUrl } = getEnvVariables();

            const response = await axiosClient.patch(`${apiRootUrl}/translation/keys/${releaseId}`, { keys: data }, { headers: { Authorization: `Bearer ${accessToken}`} });

            return response.data.data as TranslationKeyData[];
        } catch (err) {
            return Promise.reject(err as Error);
        }
    },
    deleteTranslationKeys: async (accessToken: string, releaseId: number, keys: string[]): Promise<TranslationKeyData[]> => {
        try {
            const { apiRootUrl } = getEnvVariables();

            const response = await axiosClient.delete(`${apiRootUrl}/translation/keys/${releaseId}`, { data: { keys }, headers: { Authorization: `Bearer ${accessToken}`} });

            return response.data.data as TranslationKeyData[];
        } catch (err) {
            return Promise.reject(err as AxiosError);
        }
    },
    addTranslationKeys: async (accessToken: string, releaseId: number, keys: TranslationKeyInput[]): Promise<TranslationKeyData[]> => {
        try {
            const { apiRootUrl } = getEnvVariables();

            const response = await axiosClient.post(`${apiRootUrl}/translation/keys/${releaseId}`, { keys }, { headers: { Authorization: `Bearer ${accessToken}` } });

            return response.data.data as TranslationKeyData[];
        } catch (err) {
            return Promise.reject(err as Error);
        }
    }
};

export default translationService;
import axios from "axios";
import { v4 as uuid } from 'uuid'

const axiosClient = axios.create();

axiosClient.interceptors.request.use((config) => {
    config.headers.set('correlation-id', uuid());
    return config;
})

export default axiosClient;
import { ProjectReleaseStatusData, ReleaseStatus } from "../../types/project";

export const getNextReleaseStatusData = (currentStatus?: ReleaseStatus): ProjectReleaseStatusData | undefined => {
    switch(currentStatus) {
        case ReleaseStatus.InDevelopment: {
            return {
                code: ReleaseStatus.ClientReviewPreTranslation,
                description: 'Client Review (Pre-Translation)'
            }
        }
        case ReleaseStatus.ClientReviewPreTranslation: {
            return {
                code: ReleaseStatus.Translating,
                description: 'Translating'
            }
        }
        case ReleaseStatus.Translating: {
            return {
                code: ReleaseStatus.ClientReviewPostTranslation,
                description: 'Client Review (Post-Translation)'
            }
        }
        default: {
            return undefined;
        }
    }
}
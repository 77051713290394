import React from 'react';
import Card from '../card/card';
import {
  PermittedUserActions,
  ProjectVersionWithActions as ProjectVersionType,
  ReleaseStatus as ReleaseStatusType,
} from '../../types/project';
import ReleaseStatus from '../release-status/release-status';
import Subtitle from '../subtitle/subtitle';
import generalUtil from '../../util/general';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faCircleXmark,
  faComments,
  faFileLines,
  faKey,
} from '@fortawesome/free-solid-svg-icons';
import { Button, Tooltip, useTheme } from '@mui/joy';
import { useScreenSizeContext } from '../../util/screensize-context';
import { Page } from '../../types/navigation';
import { useNavigate } from 'react-router-dom';

export type ProjectVersionProps = {
  version: ProjectVersionType;
  projectId: number;
  style?: React.CSSProperties;
};

const determineReleaseOnTime = (
  statusCode: ReleaseStatusType,
  scheduledReleaseDate: string,
  actualReleaseDate?: string,
): boolean => {
  if (statusCode === ReleaseStatusType.Released) {
    return actualReleaseDate
      ? (generalUtil.compareDates(scheduledReleaseDate, actualReleaseDate) ??
          true)
      : true;
  } else {
    return (
      generalUtil.compareDates(
        scheduledReleaseDate,
        new Date().toISOString(),
      ) ?? true
    );
  }
};

const ProjectVersion = ({
  version: {
    id,
    name,
    status,
    scheduledReleaseDate,
    actualReleaseDate,
    userActions,
  },
  projectId,
  style,
}: ProjectVersionProps) => {
  const joyUiTheme = useTheme();
  const { screenWidth } = useScreenSizeContext();

  const navigate = useNavigate();

  const isReleaseOnTime = React.useMemo(
    () =>
      determineReleaseOnTime(
        status.code,
        scheduledReleaseDate,
        actualReleaseDate,
      ),
    [status, scheduledReleaseDate, actualReleaseDate],
  );

  return (
    <Card sx={{ padding: '16px 24px', ...style }}>
      <div
        style={{ display: 'flex', gap: '24px' }}
        data-testid={`project-version-${id}`}
      >
        <div style={{ width: '12.5%', display: 'flex', alignItems: 'center' }}>
          <div style={{ fontSize: '24px', fontWeight: 600 }}>{name}</div>
        </div>
        {status.code === ReleaseStatusType.Released ? (
          <div style={{ width: '25%' }}>
            <Subtitle style={{ fontSize: '12px' }}>
              Scheduled Release Date
            </Subtitle>
            <div
              style={{
                fontWeight: 600,
                fontSize: '18px',
              }}
            >
              {generalUtil.formatDate(scheduledReleaseDate)}
            </div>
          </div>
        ) : (
          <div
            style={{
              width: screenWidth < 1220 ? '5%' : '25%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <ReleaseStatus
              status={status}
              size='sm'
              showLabel={screenWidth >= 1220}
            />
          </div>
        )}

        <div style={{ width: '20%' }}>
          <Subtitle style={{ fontSize: '12px' }}>
            {status.code === ReleaseStatusType.Released
              ? 'Actual Release Date'
              : 'Scheduled Release Date'}
          </Subtitle>
          <div
            style={{
              fontWeight: 600,
              color: isReleaseOnTime
                ? 'black'
                : joyUiTheme.colorSchemes.light.palette.danger[500],
              fontSize: '18px',
            }}
          >
            {status.code === ReleaseStatusType.Released && actualReleaseDate
              ? generalUtil.formatDate(actualReleaseDate)
              : generalUtil.formatDate(scheduledReleaseDate)}
          </div>
        </div>
        <div
          style={{
            width: '10%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Tooltip
            title={
              isReleaseOnTime
                ? status.code === ReleaseStatusType.Released
                  ? 'On Time'
                  : 'On Track'
                : status.code === ReleaseStatusType.Released
                  ? 'Late'
                  : 'Delayed'
            }
            placement='top'
            arrow
          >
            <FontAwesomeIcon
              icon={isReleaseOnTime ? faCircleCheck : faCircleXmark}
              data-testid={`release-${id}-time-status-${isReleaseOnTime ? 'ontime' : 'late'}`}
              color={
                joyUiTheme.colorSchemes.light.palette[
                  isReleaseOnTime ? 'success' : 'danger'
                ][500]
              }
              size='2x'
            />
          </Tooltip>
        </div>
        <div
          style={{
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: '16px',
          }}
        >
          {(userActions.includes(PermittedUserActions.AddAndReviewKeys) ||
            userActions.includes(PermittedUserActions.ReviewKeys)) && (
            <div>
              <Button
                startDecorator={<FontAwesomeIcon icon={faKey} />}
                onClick={() =>
                  navigate(
                    `${Page.ProjectRoute}/${projectId}/${id}/${Page.Keys}`,
                  )
                }
              >
                Label Keys
              </Button>
            </div>
          )}
          {userActions.includes(PermittedUserActions.Translate) && (
            <div>
              <Button
                startDecorator={<FontAwesomeIcon icon={faComments} />}
                onClick={() =>
                  navigate(
                    `${Page.ProjectRoute}/${projectId}/${id}/${Page.Translate}`,
                  )
                }
              >
                Translate
              </Button>
            </div>
          )}
          {userActions.includes(PermittedUserActions.ReviewTranslations) && (
            <div>
              <Button
                startDecorator={<FontAwesomeIcon icon={faFileLines} />}
                onClick={() =>
                  navigate(
                    `${Page.ProjectRoute}/${projectId}/${id}/${Page.ReviewTranslations}`,
                  )
                }
                color={
                  userActions.includes(PermittedUserActions.Translate)
                    ? 'neutral'
                    : 'primary'
                }
              >
                Translations
              </Button>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default ProjectVersion;

import { Language } from "./misc";
import { ProjectRole } from "./user";

export type Project = {
    id: number;
    name: string;
    langs: string[];
    repoName: string;
    repoOwner: string;
    pat: string;
    users: ProjectUser[];
}

export type ProjectUser = {
    userId: string;
    role: ProjectRole;
}

export type ProjectCreateInput = {
    name: string;
    langs: string[];
    repoName: string;
    repoOwner: string;
    pat: string;
    users: ProjectUser[];
}

export type ProjectDetails = {
    id: number;
    name: string;
    langs: Language[];
    users: ProjectUser[];
    versions: ProjectVersionWithActions[];
}

export type ProjectVersionWithActions = {
    id: number;
    name: string;
    status: ProjectReleaseStatusData;
    scheduledReleaseDate: string;
    actualReleaseDate?: string;
    createdDate: string;
    userActions: PermittedUserActions[];
}

export type ProjectRelease = {
    id: number;
    projectId: number;
    versionName: string;
    createdDate: string;
    scheduledReleaseDate: string;
    actualReleaseDate?: string;
    status: ReleaseStatus;
}

export type CreateProjectReleaseInput = {
    versionName: string;
    scheduledReleaseDate: string;
}

export type ProjectListResponse = {
    id: number;
    name: string;
    langs: Language[];
    latestVersion?: {
        versionId: number,
        versionName: string,
        releaseDate: string,
        status: ProjectReleaseStatusData,
    }
    userActions: PermittedUserActions[];
}

export type BasicReleaseDetails = {
    id: number;
    versionName: string;
    projectName: string;
    userProjectRole?: ProjectRole;
    status: ProjectReleaseStatusData;
}

export type ProjectReleaseStatusData = {
    code: ReleaseStatus;
    description: string;
}

export enum ReleaseStatus {
    InDevelopment = 'INDEV',
    ClientReviewPreTranslation = 'CRPRE',
    Translating = 'TRANS',
    ClientReviewPostTranslation = 'CRPOS',
    Released = 'RELSD'
}

export enum PermittedUserActions {
    AddAndReviewKeys = 'ADDKEY',
    ReviewKeys = 'REVKEY',
    ReviewTranslations = 'REVTRN',
    Translate = 'TRANSL'
}